<template>
  <div class="OperationLog">
    <div class="topinput">
      <a-input
        placeholder="用户名"
        allowClear
        v-model:value="username"
        style="width: 165px"
      />
      <a-input
        placeholder="操作项"
        allowClear
        v-model:value="name"
        style="width: 165px"
      />
      <a-date-picker placeholder="操作日期" @change="dateChange" />
      <a-button type="primary" @click="search">
        <template #icon><SearchOutlined /></template>
        搜索
      </a-button>
    </div>
    <div class="bottomtable">
      <!-- <div class="tabletop">
        <a-dropdown>
          <a class="ant-dropdown-link" @click.prevent>
            <a-button type="primary">
              导出
              <DownOutlined />
            </a-button>
          </a>
          <template #overlay>
            <a-menu>
              <a-menu-item>
                <a href="javascript:;">导出Excel</a>
              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>
      </div> -->
      <a-table
        :columns="columns"
        :data-source="list"
        :pagination="{
          current: page,
          total: total,
        }"
        rowKey="id"
        @change="pageChange"
      >
        <!-- <template #statusSlot="{ record }">
          {{ record.status === 1 ? "成功" : "失败" }}
        </template> -->
      </a-table>
    </div>
  </div>
</template>
<script>
import { getTable } from "../../service/operationlog";
import { SearchOutlined } from "@ant-design/icons-vue";
import { onMounted, reactive, toRefs } from "@vue/runtime-core";
import {formatDate} from "../../utils/function";
const columns = [
  {
    title: "序号",
    dataIndex: "id",
    // key: "id",
  },
  {
    title: "操作用户",
    dataIndex: "user_id",
  },
  {
    title: "操作时间",
    dataIndex: "add_time",
  },
  {
    title: "操作详情",
    dataIndex: "name",
  },
  {
    title: "IP地址",
    dataIndex: "ip",
  },
  // {
  //   title: "状态",
  //   dataIndex: "status",
  //   slots: {
  //     customRender: "statusSlot",
  //   },
  // },
  {
    title: "参数",
    dataIndex: "params",
    ellipsis: true,
  },
];
export default {
  name: "OperationLog",
  setup() {
    const state = reactive({
      page: 1,
      count: 10,
      list: [],
      total: undefined,
      dateString: "",
      username: "",
      name: "",
    });
    onMounted(() => {
      let date = new Date();
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();
      if (month < 10) {
        month = "0" + month;
      }
      if (day < 10) {
        day = "0" + day;
      }
      state.dateString = year + "-" + month + "-" + day;
      // console.log(state.dateString);
      init();
    });
    const init = async () => {
      const res = await getTable({
        access_token: sessionStorage.getItem("token"),
        page: state.page,
        count: state.count,
        mdate: state.dateString,
        username: state.username,
        name: state.name,
      });
      // console.log(res);
      state.list = res.data.data.list;
      state.total = res.data.data.total;
      // console.log(state.list);
      let timeList = state.list;
      timeList.forEach((item) => {
        // console.log(item,toData);  
        item.add_time = formatDate(item.add_time);
      });
    };

    const dateChange = (date, dateString) => {
      console.log(date, dateString);
      state.dateString = dateString;
      // init();
    };
    const pageChange = (e) => {
      // console.log(e);
      state.page = e.current;
      init();
    };
    const search = () => {
      // console.log(e);
      init();
    };
    return { columns, dateChange, ...toRefs(state), pageChange, search };
  },
  components: { SearchOutlined },
};
</script>
<style>
/* .OperationLog .ant-table-thead > tr > th {
  padding: 16px 16px 16px 9px !important;
} */
</style>
<style  scoped>
.OperationLog {
  width: 100%;
  height: 100%;
  background-color: #fff;
}

.topinput {
  border-bottom: 1px solid #f7f7f7;
  padding: 20px;
  margin-bottom: 10px;
}
.ant-calendar-picker,
.ant-input-affix-wrapper {
  margin-right: 20px;
}
.bottomtable {
  padding: 0 10px;
}
/* .tabletop {
  text-align: right;
  margin-bottom: 10px;
} */
</style>