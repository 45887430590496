import http from "../axios/http"
//表格数据
export let getTable=(params)=>{
    return http.get("report/action_log",{params})
}
//登入日志表格数据
export let getLoginLog=(params)=>{
    return http.get("report/login_log",{params})
}
//系统监控
export let getSystem=(params)=>{
    return http.get("system/disk_info",{params})
}
